export default function initCallback() {
    const forms = document.querySelectorAll('.form')

    forms.forEach(form => form.addEventListener("submit", function (e) {
        e.preventDefault()
        const formAction = form.getAttribute('action')
        const nameInputValue = form.querySelector('.default-input__input[type="text"]').value
        const phoneInput = form.querySelector('.default-input__input[type="tel"]')
        const phoneInputValue = phoneInput.value


        if(!validateName(nameInputValue)) {
            window.toast.error('В имени можно использовать только русские буквы')
        }
        else if(!validatePhone(phoneInputValue)) {
            window.toast.error('Проверьте правильноcть введенного телефона')
        }
        else {
            let body = new FormData()

            body.append('name', nameInputValue)
            body.append('phone', phoneInputValue)
            if(formAction === 'callback-quiz.php') {
                const entityInput = form.querySelector('.entity-input').value
                const techInput = form.querySelector('.tech-input').value
                body.append('entity', entityInput)
                body.append('tech', techInput)
            }
            
            sendAjax()
            async function sendAjax() {
                await fetch(formAction, {
                    method: 'POST',
                    body
                })
                let inputsInForm = form.querySelectorAll('input')
                inputsInForm.forEach(input => {
                    input.value = ''
                })
                window.popup.open('#thankyou')
            }
        }
    }))

    // Разблокировка кнопки только после заполнения инпутов внутри
    forms.forEach((form) => {
        const inputsInForm = form.querySelectorAll('.form .default-input__input:not([type="hidden"])')

        inputsInForm.forEach(input => input.addEventListener("input", function () {
            if(!inputsInForm[0].value == "" && !inputsInForm[1].value == "") {
                form.querySelector('button').disabled = false
            }
            else {
                form.querySelector('button').disabled = true
            }
       }))
    })

    function validateName(name) {
        let regex = /([а-я]+)/ui
        return regex.test(name)
    }

    function validatePhone(phone) {
        let regex = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;
        return regex.test(phone)
    }
}