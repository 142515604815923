import Swiper, { Navigation, Pagination, Autoplay } from "swiper"

Swiper.use([Navigation, Pagination, Autoplay])

document.addEventListener("DOMContentLoaded", () => {
    initSliders()
})

function initSliders() {
    const showcaseSlider = document.querySelector(".showcase__slider .swiper")
    new Swiper(showcaseSlider, {
        slidesPerView: 1,
        autoplay: {
            delay: 4500,
        },
        navigation: {
            prevEl: ".showcase__slider .swiper-nav__prev",
            nextEl: ".showcase__slider .swiper-nav__next",
        },
        pagination: {
            el: showcaseSlider.closest('.showcase')?.querySelector(".swiper-pagination"),
            clickable: true,
        },
    })

    const analogSliders = document.querySelectorAll(".analog__slider .swiper")
    analogSliders.forEach(analogSlider => {
        const initialSlidesPerView = analogSlider.querySelectorAll(".swiper-slide").length
        console.log(initialSlidesPerView)
        new Swiper(analogSlider, {
            slidesPerView: initialSlidesPerView,
            navigation: {
                //prevEl: analogSlider.closest('.p-goods').querySelector(".swiper-nav__prev"),
                //nextEl: analogSlider.closest('.p-goods').querySelector(".swiper-nav__next"),
            },
            breakpoints: {
                1750: {
                    slidesPerView: initialSlidesPerView,			
                },
                1250: {
                    slidesPerView: 5,
                },			
                450: {
                    slidesPerView: 2.5,
                },
                0: {
                    slidesPerView: 1.5,					
                }
            },
        })
        
             
    })
    
    const deliverySlider = document.querySelector(".delivery__slider .swiper")
    new Swiper(deliverySlider, {
        slidesPerView: 4,
        spaceBetween: 70,
        navigation: {
            //prevEl: analogSlider.closest('.p-goods').querySelector(".swiper-nav__prev"),
            //nextEl: analogSlider.closest('.p-goods').querySelector(".swiper-nav__next"),
        },
        breakpoints: {
            1250: {
                slidesPerView: 4,			
            },
            1000: {
                slidesPerView: 3,	
                spaceBetween: 30		
            },
            768: {
                slidesPerView: 3,			
            },
            0: {
                slidesPerView: 1,					
            }
        },
        pagination: {
            el: deliverySlider.closest('.delivery').querySelector(".swiper-pagination"),
            clickable: true,
        },
    })
}

