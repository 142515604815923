
// PLUGINS======================================================================================
import Inputmask from "inputmask"
//import {Fancybox} from "@fancyapps/ui"
// import LazyLoad from "vanilla-lazyload"

// MODULES======================================================================================
import "./js-modules/sliders.js"
//import "./js-modules/yandexMap"
// import "./js-modules/select.js"

// import initTabs from "./js-modules/tabs"
import initDropdowns from './js-modules/dropdowns'
import Popup from "./js-modules/popup";
import Toast from "./js-modules/toast";
// import initAnimations from "./js-modules/animations"
import initShowmore from "./js-modules/showmore"
import initMobilemenu from "./js-modules/mobilemenu"
import initAnalogMore from "./js-modules/analogMore"
import initQuiz from "./js-modules/quiz"
import initСallback from "./js-modules/callback"
import initGetYear from "./js-modules/getYear"



// init ========================================================================================
document.addEventListener("DOMContentLoaded", () => {
    // let lazyload = new LazyLoad()
    // Fancybox.bind("[data-fancybox]", {
    //     Toolbar: true,
    //     dragToClose: false,
    //     Thumbs: false,
    // })

    let im = new Inputmask("+7 (999) 999-99-99")
    im.mask(document.querySelectorAll('input[type="tel"]'))
    document.querySelectorAll('input[type="tel"]').forEach(input => {
        input.setAttribute("inputmode","numeric")
    })

    initAnalogMore()
    initShowmore()
    initDropdowns()
    initQuiz()
    window.popup = new Popup('[data-popup]')
    window.toast = new Toast()
    // initTabs()
    initMobilemenu()
    initСallback()
    initGetYear()
    // initAnimations()
})
