export default function initQuiz() {
    const quizSteps = document.querySelectorAll('.quiz__step')
    const quizNext = document.querySelectorAll('.quiz__next')
    const quizAgain = document.querySelectorAll('.quiz__again')
    const quizContainer = document.querySelector('.popup--quiz')

    const hiddenInputs = {
        entityInput: document.querySelector('.entity-input'),
        techInput: document.querySelector('.tech-input')
    }

    quizNext.forEach(btn => btn.addEventListener('click', function () {
        let currentStep = Number(btn.closest('.quiz__step').getAttribute('data-step'))
        toggleNextStep(currentStep)    
    }))

    quizAgain.forEach(btn => btn.addEventListener('click', function() {
        quizSteps.forEach((quizStep) => {
            quizStep.classList.remove('active')
        })
        quizSteps[1].classList.add('active')
    }))

    function toggleNextStep(currentStep) {
        quizSteps.forEach((quizStep) => {
            quizStep.classList.remove('active')
        })
        let step = currentStep +=1
        document.querySelector(`[data-step="${step}"]`).classList.add('active')

        if(step === 2) {
            chooseEntity(step)
        }
        if(step === 3) {
            watchChangeInputs(step)
        }
        if(step === 4) {
            fillHiddenInputs()
        }
    }

    function chooseEntity(step) {
        quizContainer.querySelector('.popup__block').classList.add('popup__block--mini')
        let entityItems = document.querySelectorAll('.o-entity__item')
        entityItems.forEach(entityItem => entityItem.addEventListener('click', function() {
            entityItems.forEach((entityItem) => {
                entityItem.classList.remove('active')
            })
            entityItem.classList.add('active')

            unlockNextButton(step)
        }))
    }

    function watchChangeInputs(step) {
        let inputs = document.querySelectorAll(`.quiz__step--${step} input`)
        inputs.forEach(input => input.addEventListener('change', function() {
            let checkedCheckbox = document.querySelectorAll(`.quiz__step--${step} input:checked `)
            let fillInput = document.querySelector(`.quiz__step--${step} .default-input__input `).value

            if(checkedCheckbox.length > 0) {
                unlockNextButton(step)
            }
            else if (!fillInput == '') {
                unlockNextButton(step)
            }
            else {
                lockNextButton(step)
            }
        }))
    }
 
    function unlockNextButton(step) {
        document.querySelector(`.quiz__step[data-step="${step}"] .quiz__next`).classList.remove('disabled')
    }
    function lockNextButton(step) {
        document.querySelector(`.quiz__step[data-step="${step}"] .quiz__next`).classList.add('disabled')
    }

    function fillHiddenInputs() {
        let selectedEntity = document.querySelector('.o-entity__item.active').textContent.trim()
        let techCheckboxes = document.querySelectorAll('.quiz__checkboxes .default-checkbox__input:checked')
        let techInput = document.querySelector('.quiz__input .default-input__input')
        let techString = ''

        techCheckboxes.forEach(checkbox => {
            techString += checkbox.name + ', '
        })

        if(techInput.value) {
            techString += techInput.value
        }

        hiddenInputs.entityInput.value = selectedEntity
        hiddenInputs.techInput.value = techString
    }
}