export default function initShowmore() {
    const textBlock = document.querySelector('.text')
    const textMoreBtn = document.querySelector('.text__more')

    if(textBlock) {
        textMoreBtn.addEventListener('click', function() {
            textBlock.classList.toggle('active')
        })

    }

}