/**
 * @param {querySelector} burger - burger selector 
 * @param {querySelector} menu - menu selector
 */
export default function initMobilemenu(burgerClass, menuClass) {
    const burger = document.querySelector(".header__burger")
    if (!burger) return
    const body = document.body
    const menu = document.querySelector(".header__mobilemenu")

    burger.addEventListener("click", () => {
        if (body.classList.contains("mobilemenu-opened")) {
            document.removeEventListener("click", outsideEvtListener)
        } else {
            setTimeout(() => {
                document.addEventListener("click", outsideEvtListener)
            })
        }
        burger.classList.toggle("opened")
        menu.classList.toggle("active")
        body.classList.toggle("mobilemenu-opened")
    })


    function outsideEvtListener(evt) {
        if (evt.target === menu || menu.contains(evt.target)) return
        burger.classList.toggle("opened")
        menu.classList.toggle("active")
        body.classList.toggle("mobilemenu-opened")
        document.removeEventListener("click", outsideEvtListener)
    }

    let menuItems = document.querySelectorAll('.header__mobilemenu .header__menu-item')

    menuItems.forEach(item => item.addEventListener('click', function() {
        burger.click()
    }))
}
