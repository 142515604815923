export default function initAnalogMore(params) {
    const analogMore = document.querySelector('.analog__more')
    const analogSlider = document.querySelector('.analog__slider')

    if(analogMore) {
        analogMore.addEventListener('click', function() {
            this.classList.toggle('active')
            analogSlider.classList.toggle('active')
        })
    }
}
